import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper, FlexBox, Text, Icon, Section } from '../../../modules/globalStyle';
import { css } from '../../../modules/index';
import { Headers } from '../../../components/Headers/index';
import { NoticeItem } from '../../../components/Notice/index';
import { useStore } from '../../../store';
import CountUp from 'react-countup';
import { Image } from 'antd';

export const HomeTop = observer(() => {
  const { lang, base, god } = useStore();
  return (
    <article className={styles.homeTop.className}>
      <Wrapper>
        <Headers />

        <NoticeItem
          content={
            <div>
              {lang.t('notice.txt1')}&nbsp;
              <a href='https://docs.cyclone.xyz/audit'>{lang.t('audited.code')}</a>&nbsp;
              {lang.t('notice.txt2')}
            </div>
          }
        />
        {god.isIOTX && (
          <NoticeItem
            content={
              <div>
                {lang.t('iotex.v3.notice')}&nbsp;
                <a href='https://iotex-v2.cyclone.xyz/iotx'>{lang.t('iotex.v3.notice.link')}</a>,&nbsp;
              </div>
            }
          />
        )}

        <FlexBox justify='between' responsive='rc'
                 css={{ mt: '2.625rem', alignItems: 'stretch', '@md': { alignItems: 'center' } }}>
          <Section css={{ mb: '3rem', '@md': { mb: 0 } }}>
            <Text size='large' color='white' weight='medium' css={{ mb: '0.5rem' }} responsive='large'>
              <span>{lang.t('tvl')}: </span>
              {base.tvl ? (
                <CountUp
                  end={base.tvl}
                  duration={1}
                  decimals={2}
                  preserveValue
                  formattingFn={(e) => `$${e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                />
              ) : (
                '...'
              )}
            </Text>
            <Text size='medium' color='light' responsive='large'>
              <span>{lang.t('token.price')}: $</span>
              <CountUp end={base.cycPrice} duration={1} decimals={2} preserveValue />
            </Text>
          </Section>
          <Section>
            {god.isETH && (
              <>
                <FlexBox justify='end' css={{ mb: '0.4rem' }} onClick={(e) => god.addCYCToMetamask()}>
                  <Text size='small' color='light' hover='light' cursor='pointer'>
                    {lang.t('add.metamask')}
                  </Text>
                  <Icon src='/images/home/metamask_logo.png' css={{ marginLeft: '0.8rem' }} alt='' />
                </FlexBox>
                <a href={god.currentChain.swapURL} target='_blank'>
                  <FlexBox justify='end'>
                    <Text size='small' color='light' hover='light' cursor='pointer'>
                      {lang.t('add.to', { target: god.currentChain.swapName })}
                    </Text>
                    <Icon src={`/images/home/${god.currentChain.swapName.toLowerCase()}.png`}
                          css={{ marginLeft: '0.8rem' }} alt='' />
                  </FlexBox>
                </a>
              </>
            )}
            {/* {god.isIOTX && (
              <a href={god.currentChain.swapURL} target="_blank">
                <FlexBox justify="end">
                  <Text size="small" color="light" hover="light" cursor="pointer">
                    {lang.t('trade.on', { target: god.currentChain.swapName })}
                  </Text>
                  <Icon src={`/images/home/${god.currentChain.swapName.toLowerCase()}.png`} css={{ marginLeft: '0.8rem' }} alt="" />
                </FlexBox>
              </a>
            )} */}
          </Section>
        </FlexBox>
        {
          god.isIOTX && (
            <FlexBox justify='between' responsive='rc' css={{
              backgroundImage: 'url(\'/images/banner_zoomswap.png\')',
              minHeight: '130px',
              padding: '1rem 1.25rem'
            }}>
              <Image src='/images/space station_h.png' />
              <Text color='white' size='medium' css={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{__html:lang.t('iotex.cyclone')}} />
              <FlexBox justify='center' color='#38DCD5'
                       style={{
                         border: '1px solid #38DCD5',
                         color: '#38DCD5',
                         width: '120px',
                         height: '40px',
                         fontWeight: 600,
                         fontSize: '14px',
                         padding: '2px',
                         lineHeight: '17px'
                       }}>
                <a href='https://zoomswap.io/cyc-pools' style={{color:'#38DCD5'}}>{lang.t('stake.now')}</a>
              </FlexBox>
            </FlexBox>
          )
        }
      </Wrapper>
    </article>
  );
});

const styles = {
  homeTop: css({
    padding: '1.5rem 0 0 0'
  }),
  miniLine: css({
    width: '100px',
    height: 1,
    backgroundColor: '$primary',
    marginTop: '1.5rem'
  })
};
