import { LPTokenState, TokenState } from '../store/lib/TokenState';
import { ContractState, AeolusContractState } from '../store/lib/ContractState';
import AeolusV2ABI from '../../public/contracts/AeolusV2.json';
import PancakePairABI from '../../public/contracts/pancakePair.json';
import XRC20ABI from '../../public/contracts/XRC20.json';
import CYCLONEABI from '../../public/contracts/IOTXCyclone.json';
import { PoolState } from '../store/lib/PoolState';
import { ChainState } from '../store/lib/ChainState';
import CYCLONEV2Dot2ABI from '../../public/contracts/CycloneV2dot2.json';
import UniswapV2CycloneRouterABI from '../../public/contracts/UniswapV2CycloneRouter.json';

export const IotexMainnetConfig = new ChainState({
  name: 'Iotex Mainnet',
  chainId: 4689,
  key: 'iotex',
  rpcUrl: 'https://babel-api.mainnet.iotex.io/',
  explorerURL: 'https://iotexscan.io',
  explorerName: 'IotexScan',
  swapName: 'MimoSwap',
  swapURL: 'https://mimo.exchange/#/swap?outputCurrency=0x4D7b88403AA2F502bf289584160db01ca442426c',
  APIURL: 'https://analytics-iotex.cyclone.xyz/query',
  relayer: 'https://cyclone-iotex-web3-relayer.onrender.com',
  metas: {
    rewardPerBlock: 5
  },
  Coin: new TokenState({
    symbol: 'IOTX',
    decimals: 18
  }),
  Aeolus: new AeolusContractState({
    deprecated: true,
    name: 'CYC-IOTX LP V1 (Inactive)',
    lpName: 'CYC/IOTX Mimo V1 LP token',
    address: '0x9286e9271bf497ec39b3fdaef53e38bfc6b4cf14',
    removeLiquidity: 'https://v1.mimo.exchange/remove-liquidity?source=iotx&dest=io1f4acssp65t6s90egjkzpvrdsrjjyysnvxgqjrh',
    abi: AeolusV2ABI
  }),
  AeolusV2: new AeolusContractState({
    deprecated: true,
    name: 'CYC-IOTX LP V2 (Inactive)',
    lpName: 'CYC/IOTX Mimo V1 LP token',
    address: '0x1a1dd52afad4f243c31f0906d019c4acdf81484b',
    migrateV2: true,
    removeLiquidity: 'https://v1.mimo.exchange/remove-liquidity?source=iotx&dest=io1f4acssp65t6s90egjkzpvrdsrjjyysnvxgqjrh',
    abi: AeolusV2ABI
  }),
  AeolusV3: new AeolusContractState({
    name: 'CYC-IOTX LP V3',
    lpName: 'CYC/IOTX Mimo V2 LP token ',
    address: '0xaCf00a84559F536bA64064a4C73B74698013Ef36'.toLowerCase(),
    addLiquidity: 'https://mimo.exchange/#/add/IOTX/${token}',
    abi: AeolusV2ABI
  }),
  MultiCall: new ContractState({
    address: '0xacce294bf7d25fe8c5c64ae45197d3878f68403b'
  }),
  LPToken: new LPTokenState({
    address: '0x129db5e89eae1782fea4afc9e8a2c4985e1adfb2',
    abi: PancakePairABI,
    decimals: 18
  }),
  LPTokenV2: new LPTokenState({
    address: '0x129db5e89eae1782fea4afc9e8a2c4985e1adfb2',
    abi: PancakePairABI,
    decimals: 18
  }),
  LPTokenV3: new LPTokenState({
    address: '0x1381b170681074fedaf1c4e35be1880bc4e85c4a',
    abi: PancakePairABI,
    decimals: 18
  }),
  CYCToken: new TokenState({
    address: '0x4d7b88403aa2f502bf289584160db01ca442426c',
    abi: XRC20ABI,
    decimals: 18,
    symbol: 'CYC'
  }),
  UniswapV2CycloneRouter: new ContractState({
    address: '0x9fFCE759519E77BB70d008cEDB999e054e229F38',
    abi: UniswapV2CycloneRouterABI
  }),
  pools: {
    '3': new PoolState({
      id: 3,
      version: 1,
      set: 'Pool 1- Squid',
      address: 'io15w9kwskwl9tn7luhcwrj0rarzjp988pafg07uf',
      abi: CYCLONEABI
    }),
    '4': new PoolState({
      id: 4,
      version: 1,
      set: 'Pool 2- Dolphin',
      address: 'io1wcd67wk36e3r8eku8scv7g7azsfnqs7z3e38xg',
      abi: CYCLONEABI
    }),
    '5': new PoolState({
      id: 5,
      version: 1,
      set: 'Pool 3- Shark',
      address: 'io1v667xgkux8uv0gell53ew5tr090c69k85deezn',
      abi: CYCLONEABI
    }),
    '6': new PoolState({
      id: 6,
      version: 1,
      set: 'Pool 4- Whale',
      address: 'io1wnaks7kectrkxk5v4d7mh97jkqjl4p0690jxfx',
      abi: CYCLONEABI
    }),
    '0': new PoolState({
      id: 0,
      version: 1,
      set: 'Early Sparrow',
      address: 'io1rqm2keejw4jypgl0w6hr96r054ns63u0hqrfuy',
      abi: CYCLONEABI
    }),
    '1': new PoolState({
      id: 1,
      version: 1,
      set: 'Early Raven',
      address: 'io1gkeeljp4grwskgq3tl2xqglqy546nhhmytnqqp',
      abi: CYCLONEABI
    }),
    '2': new PoolState({
      id: 2,
      version: 1,
      set: 'Early Eagle',
      address: 'io1mlcgsv4ma0t6gffpxg2gva3lwqnlj5msradxk6',
      abi: CYCLONEABI
    }),
    '16': new PoolState({
      id: 16,
      version: 2.3,
      set: 'Biscuit - 50,000 IOTX',
      address: '0x8fc2ac1cf90766492237a9156f93a6a4147424c5',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'IOTX',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '17': new PoolState({
      id: 17,
      version: 2.3,
      set: 'Snickerdoodle - 500,000 IOTX',
      address: '0x92a737097d711bec4c31351997254e98e5f0d430',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'IOTX',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '18': new PoolState({
      id: 18,
      version: 2.3,
      set: 'Gingersnaps - 2,000,000 VITA',
      address: '0x96de31c4f4ebaac302c29d586511f0f4ba77e579',
      XRCToken: new TokenState({
        abi: XRC20ABI,
        address: '0xb8744ae4032be5e5ef9fab94ee9c3bf38d5d2ae0',
        decimals: 18,
        symbol: 'VITA'
      }),
      abi: CYCLONEV2Dot2ABI,
      symbol: 'VITA',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '23': new PoolState({
      id: 23,
      version: 2.3,
      set: 'Macaron - 3 CYC',
      address: '0xb80d026a7faa8c35def0e430f9b36163ac949c19',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'CYC',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '24': new PoolState({
      id: 24,
      version: 2.3,
      set: 'BUSD_b - 2,500 BUSD_b',
      address: '0xdfbdf24b8019ef44f321de54d456ddd216e73163',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'BUSD',
      XRCToken: new TokenState({
        abi: XRC20ABI,
        address: '0x84abcb2832be606341a50128aeb1db43aa017449',
        decimals: 18,
        symbol: 'BUSD'
      }),
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '25': new PoolState({
      id: 25,
      version: 2.3,
      set: 'ZM - 20000 ZM',
      address: '0xfa886fe8d27b284525974ddc6e2c0cb858a57251',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'ZM',
      XRCToken: new TokenState({
        abi: XRC20ABI,
        address: '0xf87aed04889a1dd0159d9C22B0D57b345Ab16dDD',
        decimals: 18,
        symbol: 'ZM'
      }),
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '26': new PoolState({
      id: 26,
      version: 2.3,
      set: 'MCN - 10 MCN',
      address: '0x0ad3bee1ee270339c921fb20686bfd90245ee5d8',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'MCN',
      XRCToken: new TokenState({
        abi: XRC20ABI,
        address: '0x3fe04320885e6124231254c802004871be681218',
        decimals: 18,
        symbol: 'MCN'
      }),
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '27': new PoolState({
      id: 27,
      version: 2.3,
      set: 'USDT - 2500 USDT',
      address: '0xFCC0F2BC2b2eAaB6D11039a2b8AC4b521f9e4f4A',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'USDT',
      XRCToken: new TokenState({
        abi: XRC20ABI,
        address: '0x6fbcdc1169b5130c59e72e51ed68a84841c98cd1',
        decimals: 6,
        symbol: 'USDT'
      }),
      decimals: 6,
      rawRelayerFee: '1000000000000000000'
    }),
    // '28': new PoolState({
    //   id: 28,
    //   version: 2.3,
    //   set: 'FILDA - 200 FILDA',
    //   address: '0x26Fb383CA8120f8bf9e6AeF028B28B047B208f49',
    //   abi: CYCLONEV2Dot2ABI,
    //   symbol: 'FILDA',
    //   XRCToken: new TokenState({
    //     abi: XRC20ABI,
    //     address: '0x32085b8ea854529178bd0f4e92d3fd2475a3a159',
    //     decimals: 18,
    //     symbol: 'FILDA'
    //   }),
    //   decimals: 6,
    //   rawRelayerFee: '1000000000000000000'
    // }),
    '29': new PoolState({
      id: 29,
      version: 2.3,
      set: 'XIM - 200 XIM',
      address: '0xde6354B1740CC8BE9a108965d2402F1F7Fadf989',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'XIM',
      XRCToken: new TokenState({
        abi: XRC20ABI,
        address: '0xec690cdd448e3cbb51ed135df72301c3265a8f80',
        decimals: 18,
        symbol: 'XIM'
      }),
      decimals: 6,
      rawRelayerFee: '1000000000000000000'
    }),
    '30': new PoolState({
      id: 30,
      version: 2.3,
      set: 'GFT - 20 GFT',
      address: '0x274a60dFB0F5D438E3F489e6cA2A4898Bc0630b1',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'GFT',
      XRCToken: new TokenState({
        abi: XRC20ABI,
        address: '0x17df9fbfc1cdab0f90eddc318c4f6fcada730cf2',
        decimals: 18,
        symbol: 'GFT'
      }),
      decimals: 6,
      rawRelayerFee: '1000000000000000000'
    }),
    '106': new PoolState({
      id: 106,
      version: 2.3,
      set: 'Test',
      dev: true,
      address: '0x8b144b25598DbB20DC6AD579Cc5e78C077b6925b',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'IOTX',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    })
  }
});
