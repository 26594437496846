import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { css } from '../../modules/index';
import { FlexBox } from '../../modules/globalStyle';
import { message, Divider } from 'antd';
import { CountAnimate } from '../../components/CountAnimate';
import ModalView from '../../components/ModalView';
import HighLineChart from '../../components/HighLineChart';
import Loading from '../../components/Loading';
import { useStore } from '../../store';
import { analyticsClient } from '../../lib/analytics-gql';
import { analyticsBscClient, analyticsETHClient, analyticsIOTEXV2CLient, analyticsPolygonClient } from '../../lib/analytics-bsc-gql';
import God from '../../God';
import { LinksLanguage } from '../../components/LinksLanguage';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';


const tabNavs = [
  {name: 'Polygon', src: '/images/home/matic_s.svg',  client:  analyticsPolygonClient},
  {name: 'IoTeX V2', src: '/images/iotex-logo.svg',  client:  analyticsIOTEXV2CLient},
  {name: 'ETH', src: '/images/home/token_eth.png',  client: analyticsETHClient },
  {name: 'BSC', src: 'images/binance-logo.svg',  client:   analyticsBscClient},
  {name: 'IoTeX V1', src: '/images/iotex-logo.svg',  client:  analyticsClient},
]

export const AnalysisPage = observer(() => {
  const { lang, god, base, iotex: pageStore } = useStore();
  const [iotx, setIotx] = useState(0);
  const [deposited, setDeposited] = useState(0);
  const [pool, setPool] = useState(0);
  const [fee, setFee] = useState(0);
  const [usd, setUsd] = useState(0);
  const [apy, setApy] = useState(0);
  const [firstChartData, setChartFirst] = useState(null);
  const [secondChartData, setChartSecond] = useState(null);
  const [thirdChartData, setChartThird] = useState(null);
  const [fourthChartData, setChartFourth] = useState(null);
  const [current, setCurrent] = useState('');
  const [totalCyc, setTotalCyc] = useState('');
  // basic use change tab active , this language is chinese not support actvie
  const [basic, setBasic] = useState(0);
  const [client, setClient] = useState(tabNavs[0].client);
  

  useEffect(() => {
    God.getAntena();
    init();
  }, [basic]);

  const init = () => {
    if (basic === 3) {
      getTotalCyc();
    } else {
      getLiquidityApy()
    }
    getTotal();
    getCycTokensPerDay();
    getTokensInPoolPerDay();
    getTotalTxVolumePerDay();
  };

  // get iotx and fee
  const getTotal = async () => {
    const result = basic === 4 ? { iotx: 1, fee: 1 } : { deposit: 1, pool: 1, cycSupply: 1, fee: 1, lpLocked: 1 };
    client.chain.query
      .total()
      .execute(result)
      .then((res) => {
        console.log('basic', basic)
        if (basic === 3) {
          setIotx(res.iotx);
          getPrice(res.iotx);
          setFee(res.fee);
        } else {
          setDeposited(res.deposit);
          setPool(Number(res.pool) + Number(res.lpLocked));
          setTotalCyc(res.cycSupply);
          setFee(res.fee);
          getPrice(1);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('failed get total');
      });
  };

  const getLiquidityApy = async () => {
    client.chain.query
      .apy()
      .execute({address: 1, apy: 1, })
      .then((res) => {
        const apy = new BigNumber(res[res.length - 1].apy).multipliedBy(100 * 365).toFixed(2)
        setApy(apy)
      })
  }

  // get usd
  const getPrice = (n) => {
    const params = basic === 4 ? {} : { symbol: basic === 'BSC' ? 'bnb' : 'eth'};
    client.chain.query
      .price(params)
      .execute({ usd: 1 })
      .then((res) => {
        if (basic === 3) {
          setUsd(res.usd * n);
          getTotalPoolPerDay(res.usd);
        } else {
          setUsd(res.usd);
          getTotalPoolPerDay(1);
        }
      })
      .catch((err) => {
        message.error('failed get price');
      });
  };

  // Tokens in Each Pool Per Day
  const getTokensInPoolPerDay = () => {
    client.chain.query
      .tokensInPoolPerDay({ count: 30 })
      .execute({
        name: '',
        values: {
          timestamp: 1,
          value: 1
        }
      })
      .then((res) => {
        setChartFirst(res);
      })
      .catch((err) => {
        message.error('failed get tokensInPoolPerDay');
      });
  };

  // CYC Tokens Per Day
  const getCycTokensPerDay = () => {
    client.chain.query
      .cycTokensPerDay({ count: 30 })
      .execute({ timestamp: 1, value: 1 })
      .then((res) => {
        setChartSecond(res.reverse());
      })
      .catch((err) => {
        message.error('failed get cycTokensPerDay');
      });
  };

  // USD in Pool Per Day
  const getTotalPoolPerDay = (price) => {
    client.chain.query
      .totalPoolPerDay({ count: 30 })
      .execute({ timestamp: 1, value: 1 })
      .then((res) => {
        res = res.reverse().map((item) => {
          return {
            ...item,
            value: item.value * price
          };
        });
        setChartThird(res);
      })
      .catch((err) => {
        message.error('failed get totalPoolPerDay');
      });
  };

  // Total TX Volume Per Day
  const getTotalTxVolumePerDay = () => {
    client.chain.query
      .totalTxVolumePerDay({ count: 30 })
      .execute({ timestamp: 1, value: 1 })
      .then((res) => {
        setChartFourth(res.reverse());
      })
      .catch((err) => {
        message.error('failed get totalTxVolumePerDay');
      });
  };

  const getTotalCyc = async () => {
    setApy(await base.calculateAPY());
    setTotalCyc(await God.asyncGetTotalCycSupplyOfMimo());
  };

  const changeSymbol = (value) => {
    resetData();
    setClient(tabNavs[value].client);
    setBasic(value);
  };

  const chartObject = [
    { name: lang.t('analysis.chart.title1'), list: firstChartData,  },
    { name: lang.t('analysis.chart.title2'), list: secondChartData, },
    { name: lang.t('analysis.chart.title3'), list: thirdChartData, },
    { name: lang.t('analysis.chart.title4'), list: fourthChartData,}
  ];

  const resetData = () => {
    setApy(0);
    setTotalCyc(0);
    setChartFirst(null);
    setChartSecond(null);
    setChartThird(null);
    setChartFourth(null);
  };

  const tabMenu = (
    <ul>
      {
        tabNavs.map((item, index) => {
          return <div style={{display: 'flex'}} key={item.name}>
            <li
              className={basic === index ? 'active' : ''}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
              onClick={() => (basic === index ? null : changeSymbol(index))}
            >
              <img src={item.src} alt="" style={{ width: 20, borderRadius: '50%', marginRight: '0.5rem' }} />
              {item.name}
            </li>
            {index < tabNavs.length-1 && <div className="line">|</div>}
          </div>
        })
      }
    </ul>
  );

  return (
    <div className={styles.analysis.className} style={{ overflow: current !== '' ? 'hidden' : '', height: current !== '' ? '100vh' : 'auto' }}>
      <div className={styles.headers.className}>
        <Link to="/">
          <img src="images/logo.png" className="logo" alt="" />
        </Link>
        <span>{lang.t('Cyclone.Protocol.Analytics')}</span>
        <div className={`${styles.nav.className} largeSelect`}>{tabMenu}</div>
      </div>
      <div className={`${styles.nav.className} mobileSelect`}>{tabMenu}</div>
      {chartObject[2].list ? (
        <>
          <div className={`${styles.countAnimateBox.className} ${basic === 'IoTeX' ? 'iotexCountBox' : 'bscCountBox'}`}>
            {basic === 'IoTeX' ? (
              <>
                <CountAnimate tips={lang.t('analysis.num.title1')} value={iotx} time={lang.t('min.ago')} />
                <CountAnimate tips={lang.t('analysis.num.title2')} value={usd} time={lang.t('min.ago')} />
                <CountAnimate tips={lang.t('analysis.num.title3')} value={fee} time={lang.t('min.ago')} />
                <CountAnimate tips={lang.t('analysis.num.title4')} value={totalCyc} time={lang.t('min.ago')} />
                <CountAnimate tips={lang.t('analysis.num.title5')} decimals={2} value={apy} time={lang.t('min.ago')} unit="%" />
              </>
            ) : (
              <>
                <CountAnimate tips={lang.t('analysis.bsc.num.title1')} hideTip={true} value={deposited} time={lang.t('min.ago')} />
                <CountAnimate tips={lang.t('analysis.bsc.num.title2')} hideTip={true} value={pool} time={lang.t('min.ago')} />
                <CountAnimate tips={lang.t('analysis.bsc.num.title3')} hideTip={true} value={fee} time={lang.t('min.ago')} />
                <CountAnimate tips={lang.t('analysis.bsc.num.title4')} hideTip={true} value={totalCyc} time={lang.t('min.ago')} />
                <CountAnimate tips={lang.t('analysis.num.title5')} decimals={2} hideTip={true} value={apy} time={lang.t('min.ago')} unit="%" />
              </>
            )}
          </div>
          <div className={styles.chartObject.className}>
            {chartObject.map((item, index) => {
              return (
                item.list &&
                item.list.length !== 0 && (
                  <HighLineChart
                    key={item.name}
                    type={index === 0 ? 'more' : 'one'}
                    data={item.list}
                    toggleScaleBig={() => setCurrent(index)}
                    title={item.name}
                    close={false}
                    time={lang.t('min.ago')}
                  />
                )
              );
            })}
          </div>
          <FlexBox justify="center" css={{ my: '4rem' }}>
            <LinksLanguage />
          </FlexBox>
        </>
      ) : (
        <ModalView>
          <Loading />
        </ModalView>
      )}
      {current !== '' && chartObject[current].list && (
        <ModalView>
          <div className={styles.modalChart.className}>
            <HighLineChart
              toggleScaleBig={() => setCurrent('')}
              type={current === 0 ? 'more' : 'one'}
              close={true}
              data={chartObject[current].list}
              title={chartObject[current].name}
              time={lang.t('min.ago')}
            />
          </div>
        </ModalView>
      )}
    </div>
  );
});

const styles = {
  analysis: css({
    width: '100vw',
    overflowX: 'hidden',
    padding: '0 1rem',
    boxSizing: 'border-box',
    color: '#fff',
    '.cyclone-footer': {
      justifyContent: 'center !important',
      marginTop: '2rem',
      paddingBottom: '12vh',
      ul: {
        width: 'max-content'
      }
    },
    '.largeSelect': {
      display: 'none',
      '@md': {
        display: 'block'
      }
    },
    '.mobileSelect': {
      display: 'block',
      marginBottom: '1rem',
      '@md': {
        display: 'none'
      }
    },
    '.iotexCountBox': {
      '@md': {
        '.countAnimateItem': {
          maxWidth: '19%'
        }
      }
    },
    '.bscCountBox': {
      '@md': {
        '.countAnimateItem': {
          maxWidth: '24%'
        }
      }
    }
  }),
  headers: css({
    textAlign: 'center',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginTop: '2rem',
    marginBottom: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    img: {
      width: '8rem'
    },
    span: {
      flex: 'inherit'
    },
    '@md': {
      fontSize: '1.125rem',
      justifyContent: 'flex-start',
      span: {
        flex: '1'
      },
      img: {
        width: '9rem'
      }
    }
  }),
  nav: css({
    ul: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
      '@md': {
        width: '100%'
      },
      li: {
        fontSize: '1rem',
        padding: '2px 12px',
        fontWeight: 400,
        cursor: 'pointer',
        '&:hover': {
          color: 'rgb(56, 220, 213)'
        },
        '@md': {
          width: 'max-content'
        }
      },
      '.active': {
        color: 'rgb(56, 220, 213)'
      },
      '.line': {
        fontWeight: 400,
        fontSize: '1rem'
      }
    }
  }),
  modalChart: css({
    width: '90%',
    '@md': {
      '.lineChartBox': {
        maxWidth: '800px !important',
        margin: 'auto',
        backgroundColor: '#000'
      }
    }
  }),
  countAnimateBox: css({
    display: 'flex',
    flexDirection: 'column',
    '@md': {
      justifyContent: 'space-between',
      flexDirection: 'row'
    }
  }),
  chartObject: css({
    display: 'flex',
    flexDirection: 'column',
    '@md': {
      justifyContent: 'space-between',
      flexDirection: 'row',
      flexWrap: 'wrap'
    }
  })
};
