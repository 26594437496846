import { analyticsBscClient, analyticsETHClient, analyticsPolygonClient, analyticsIOTEXV2CLient } from '../lib/analytics-bsc-gql';
import { publicConfig } from '../config/public';

export const marketUtils = {
  async getTVL() {
    const [bscTotal, ethTotal, polygonTotal, iotexTotal] = await Promise.all([
      analyticsBscClient.chain.query.total.execute({ lpLocked: 1, pool: 1 }),
      analyticsETHClient.chain.query.total.execute({ lpLocked: 1, pool: 1 }),
      analyticsPolygonClient.chain.query.total.execute({ lpLocked: 1, pool: 1 }),
      analyticsIOTEXV2CLient.chain.query.total.execute({ lpLocked: 1, pool: 1 })
    ]);
    return (
      Number(bscTotal.lpLocked) +
      Number(bscTotal.pool) +
      Number(ethTotal.lpLocked) +
      Number(ethTotal.pool) +
      Number(polygonTotal.lpLocked) +
      Number(polygonTotal.pool) +
      Number(iotexTotal.lpLocked) +
      Number(iotexTotal.pool)
    );
  },
  async getSupply() {
    const [iotex, bsc, eth, polygon] = await Promise.all([
      analyticsIOTEXV2CLient.chain.query.total.execute({ cycSupply: 1 }),
      analyticsBscClient.chain.query.total.execute({ cycSupply: 1 }),
      analyticsETHClient.chain.query.total.execute({ cycSupply: 1 }),
      analyticsPolygonClient.chain.query.total.execute({ cycSupply: 1 })
    ]);
    return iotex.cycSupply + bsc.cycSupply + eth.cycSupply + polygon.cycSupply;
  }
};
