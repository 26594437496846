import { makeAutoObservable } from 'mobx';
import { NetworkState, ETHNetworkState } from './lib/NetworkState';
import { EthereumConfigV2, IotexConfigV2 } from '../config/NetowkConfig';
import BigNumber from 'bignumber.js';
import { ChainState } from './lib/ChainState';
import { PoolState, PoolStateStatus } from './lib/PoolState';
import { GodUtils, DepositNote, NoteData } from '../utils/godUtils';
import { rootStore } from '.';
import { TokenState } from './lib/TokenState';
import { TransactionState } from './lib/TransactionState';
import { metamaskUtils } from '../utils/metamaskUtils';
import Config from '../Config';
import axios from 'axios';
import { LoadingState } from './lib/LoadingState';
import snarkjs from 'snarkjs';
import merkleTree from '../lib/MerkleTree';
import buildGroth16 from '../lib/groth16_browser';
import websnarkUtils from 'websnark/src/utils';
import { message } from 'antd';
import { hexToBytes } from 'web3-utils';
import { IotexNetworkState } from './lib/IotexNetworkState';
import { bytesToHex } from 'web3-utils';
import { hooks } from '../lib/hooks';
import { publicConfig } from '../config/public';
import { _ } from '../lib/lodash';
import { AeolusContractState } from './lib/ContractState';
import handler from '../../api/iotexPrice';

const Address = require('iotex-antenna/lib/crypto/address');
const unstringifyBigInts2 = require('snarkjs/src/stringifybigint').unstringifyBigInts;

const bigInt = snarkjs.bigInt;

export type Network = 'iotex' | 'eth';

export class GodStore {
  currentNetworkName: Network = 'eth';
  iotex: IotexNetworkState = IotexConfigV2;
  eth: ETHNetworkState = EthereumConfigV2;
  ABIs = new Map();
  constructor() {
    this.eth.god = this;
    makeAutoObservable(this);
  }

  get chains() {
    return [this.iotex, this.eth];
  }

  get isConnect() {
    return !!this.currentNetwork.account;
  }

  get isETH() {
    return this.currentNetworkName == 'eth';
  }
  get isIOTX() {
    return this.currentChain.key == 'iotex';
  }
  get currentNetwork(): NetworkState {
    return this.getNetwork(this.currentNetworkName);
  }

  get currentChain(): ChainState {
    return this.currentNetwork.currentChain;
  }

  get Coin() {
    return this.currentChain.Coin;
  }

  get CYCToken() {
    return this.currentChain.CYCToken;
  }

  get Aeolus() {
    return this.currentChain.Aeolus;
  }
  get AeolusV2() {
    return this.currentChain.AeolusV2;
  }
  get AeolusV3() {
    return this.currentChain.AeolusV3;
  }

  get LPToken() {
    return this.currentChain.LPToken;
  }

  get UniswapV2CycloneRouter() {
    return this.currentChain.UniswapV2CycloneRouter;
  }

  get Multicall() {
    return this.currentChain.MultiCall;
  }

  getNetwork(network: string) {
    if (network == 'iotex') {
      return this.iotex;
    } else {
      return this.eth;
    }
  }

  findPool({ poolId }: { poolId: string }) {
    let pool: PoolState;
    this.chains.forEach((network) => {
      Object.values(network.chains).forEach((chain) => {
        if (chain.pools[poolId]) {
          pool = chain.pools[poolId];
        }
      });
    });
    return pool;
  }

  getPool({
    network = this.currentNetworkName,
    chainId = this.currentChain.chainId,
    poolId
  }: {
    network?: string;
    chainId?: number | string;
    poolId: number | string;
  }) {
    return this.getNetwork(network).chains[chainId].pools[poolId];
  }

  getChain({ network = this.currentNetworkName, chainId = this.currentChain.chainId }: { network?: string; chainId?: number | string }) {
    return this.getNetwork(network).chains[chainId];
  }

  async loadPublichData() {
    console.log('load public data');
    const { Aeolus, AeolusV2 } = this.currentChain;

    const aeolusList = [Aeolus, AeolusV2 || null, this.AeolusV3 || null].filter((i) => !!i);
    const { poolList } = this.currentChain;

    this.currentNetwork.multicallV2([
      ..._.flattenDeep(aeolusList.map((aeolus) => [aeolus.preMulticall({ method: 'rewardPerBlock', handler: aeolus.rewardPerBlock })])),
      ..._.flattenDeep(
        poolList.map((pool) => [
          pool.preMulticall({ method: 'coinDenomination', handler: pool.coinDenomination }),
          pool.preMulticall({ method: 'tokenDenomination', handler: pool.tokenDenomination }),
          pool.preMulticall({
            method: 'cycDenomination',
            handler: (v: any) => {
              const cycDeno = new BigNumber(v.toString());
              pool.cycDenomination.setValue(cycDeno);
              pool.depositCYCAmount.setValue(cycDeno);
            }
          }),
          pool.preMulticall({
            method: pool.anonymityRateMethod,
            handler: (v: any) => {
              const fee = new BigNumber(v.toString());
              pool.anonymityPoolFee.setValue(fee);

              if (pool.version == 2.2 || pool.version == 2.3) {
                pool.depositCYCAmount.setFee(fee);
              } else {
                pool.depositCYCAmount.setFee(new BigNumber(0));
              }
            }
          }),
          pool.preMulticall({
            method: 'numOfShares',
            handler: (v: any) => {
              pool.numOfShare = v.toNumber();
            }
          })
        ])
      )
    ]);

    await Promise.all([this.currentNetwork.loadAnalyticsData()]);
  }

  async loadBalance() {
    this.currentNetwork.loadBalance();
  }

  async loadPrivateData() {
    console.log('load prviate data');

    const { CYCToken, Aeolus, AeolusV2, UniswapV2CycloneRouter } = this.currentChain;

    const aeolusList = [Aeolus, AeolusV2 || null, this.AeolusV3 || null].filter((i) => !!i);
    const xrcList = this.currentChain.poolList.filter((i) => i.XRCToken);
    const poolList = this.currentChain.poolList.filter((i) => i.address !== '0x7C994FB3a8C208C1750Df937d473040c604292D6');
    console.log(this.currentNetwork);
    await this.currentNetwork.multicallV2([
      CYCToken.preMulticall({
        method: 'allowance',
        params: [this.currentNetwork.account, UniswapV2CycloneRouter.address],
        handler: CYCToken.allownaceForRouter
      }),
      CYCToken.preMulticall({ method: 'balanceOf', params: [this.currentNetwork.account], handler: CYCToken.balance }),
      ..._.flattenDeep(
        aeolusList.map((aeolus) => [
          aeolus.LpToken.preMulticall({ method: 'balanceOf', params: [this.currentNetwork.account], handler: aeolus.LpToken.balance }),
          aeolus.LpToken.preMulticall({
            method: 'allowance',
            params: [this.currentNetwork.account, aeolus.address],
            handler: aeolus.LpToken.allowanceForAeolus
          }),
          aeolus.preMulticall({ method: 'pendingReward', params: [this.currentNetwork.account], handler: aeolus.cycEarn }),
          aeolus.preMulticall({
            method: 'userInfo',
            params: [this.currentNetwork.account],
            handler: (v: any) => {
              if (v) {
                aeolus.staked.setValue(new BigNumber(String(v[0] ? v[0] : v)));
              }
            }
          })
        ])
      ),
      ..._.flattenDeep(
        xrcList.map((pool) => [
          pool.XRCToken.preMulticall({ method: 'balanceOf', params: [this.currentNetwork.account], handler: pool.XRCToken.balance }),
          pool.XRCToken.preMulticall({
            method: 'allowance',
            params: [this.currentNetwork.account, UniswapV2CycloneRouter.address],
            handler: pool.XRCToken.allownaceForRouter
          })
        ])
      )
    ]);
    await this.currentNetwork.multicallV2([
      ..._.flattenDeep(
        poolList.map((pool) => [UniswapV2CycloneRouter.preMulticall({
          method: 'purchaseCost', params: [pool.address], handler: e => {
            pool.BNBtoBuyCYC.setValue(new BigNumber(e.toString()).multipliedBy(1.05));
        } })])
      )
    ]);
  }

  async approve({ token, spender, value }: { token: TokenState; spender: string; value: string }) {
    token.metas.isApprovingAllowance = true;
    const { approveMax } = rootStore.setting;
    const approveValue = approveMax.value ? publicConfig.maxApprove : value;
    try {
      const res = await this.currentNetwork.execContract({
        address: token.address,
        abi: token.abi,
        method: 'approve',
        params: [spender, approveValue]
      });
      const receipt = await res.wait();
      if (receipt.status) {
        token.metas.isApprovingAllowance = false;
        token.allownaceForRouter.setValue(new BigNumber(value));
      }
    } catch (error) {
      token.metas.isApprovingAllowance = false;
    }
  }

  async deposit(pool: PoolState) {
    try {
      pool.setStatus(PoolStateStatus.onDeposit);
      const note = GodUtils.makeNoteV2({ netId: this.currentChain.chainId, poolId: pool.id });
      const poolname = pool.set;
      const currency = this.Coin.symbol;

      if (window.navigator.userAgent.indexOf('iPhone') < 0 && note && !GodUtils.isMobile) {
        let blob = new Blob([note.note]);
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${poolname}-${currency}-${rootStore.lang.t('note')}.txt`;
        a.click();
        window.URL.revokeObjectURL(url);
      }
      await hooks.waitPendingPool({ note: note.note });

      const buyCYC = pool.allowBuyCYC ? 1 : 0;
      const toBuy = buyCYC ? pool.BNBtoBuyCYC.value.plus(pool.coinDenomination.value).toFixed(0, 1) : pool.coinDenomination.value.toFixed(0, 1)
      console.log([pool.address, note.commitment, buyCYC, toBuy]);
      pool.setStatus(PoolStateStatus.onDeposit);
      const res = await this.currentNetwork.execContract({
        address: this.UniswapV2CycloneRouter.address,
        abi: this.UniswapV2CycloneRouter.abi,
        method: 'deposit',
        params: [pool.address, note.commitment, buyCYC],
        options: { value: toBuy }
      });
      rootStore.transaction.addTransaction(
        new TransactionState({
          createTime: new Date().getTime(),
          txHash: res.hash,
          amountCYC: pool.depositCYCAmount.value.toFixed(),
          amountCoin: pool.coinDenomination.value.toFixed(),
          amountToken: pool.tokenDenomination.value.toFixed(),
          network: this.currentNetworkName,
          chainId: this.currentChain.chainId,
          poolId: pool.id,
          note: note.note,
          commitment: note.commitment
        })
      );

      const receipt = await res.wait();
      if (receipt.status) {
        pool.setStatus(PoolStateStatus.init);
        rootStore.base.setLoading({ msg: rootStore.lang.t('deposit.result1'), showCancel: false });
      }
    } catch (error) {
      console.error(error);
      pool.setStatus(PoolStateStatus.init);
      message.error(error.message);
    }
  }

  async CliamToken({ aeolus }: { aeolus: AeolusContractState }) {
    await this.currentNetwork.execContract({
      address: aeolus.address,
      abi: aeolus.abi,
      method: 'deposit',
      params: [0]
    });
  }
  async ApproveLP({ amount, aeolus }: { amount: string; aeolus: AeolusContractState }) {
    const { LpToken } = aeolus;
    LpToken.metas.isApprovingAllowance = true;
    try {
      const res = await this.currentNetwork.execContract({
        address: LpToken.address,
        abi: LpToken.abi,
        method: 'approve',
        params: [aeolus.address, amount]
      });
      const receipt = await res.wait();
      if (receipt.status) {
        LpToken.metas.isApprovingAllowance = false;
        LpToken.allowanceForAeolus.setValue(new BigNumber(amount));
      }
    } catch (error) {
      LpToken.metas.isApprovingAllowance = false;
    }
  }
  async StakeLP({ amount, aeolus }: { amount: string; aeolus: AeolusContractState }) {
    console.log(aeolus);
    const res = await this.currentNetwork.execContract({
      address: aeolus.address,
      abi: aeolus.abi,
      method: 'deposit',
      params: [amount]
    });
    const receipt = await res.wait();
    if (receipt.status) {
      rootStore.base.startRefetchForce();
    }
  }
  async UnStakeLP({ amount, aeolus }: { amount: string; aeolus: AeolusContractState }) {
    const res = await this.currentNetwork.execContract({
      address: aeolus.address,
      abi: aeolus.abi,
      method: 'withdraw',
      params: [amount]
    });
    const receipt = await res.wait();
    if (receipt.status) {
      rootStore.base.startRefetchForce();
    }
  }

  setShowConnecter(value) {
    this.currentNetwork.connector.showConnector = value;
  }

  async addCYCToMetamask() {
    metamaskUtils.registerToken(this.CYCToken.address, this.CYCToken.symbol, this.CYCToken.decimals, Config.baseURL + '/images/logo.svg');
  }

  // withdraw
  provingKey = new LoadingState({ loading: true });
  verificationKey = new LoadingState({ loading: true });
  circuit = new LoadingState({ loading: true });

  async readProvingKey() {
    if (this.provingKey.value) {
      return this.provingKey.value;
    }

    this.provingKey.setLoading(true);

    let response = await fetch(Config.assetURL + '/circuits/withdraw_proving_key.bin');
    let ab = await response.arrayBuffer();
    this.provingKey.setValue(ab);
    this.provingKey.setLoading(false);

    return this.provingKey;
  }

  async readVerificationKey() {
    if (this.verificationKey.value) {
      return this.verificationKey.value;
    }

    this.verificationKey.setLoading(true);
    this.verificationKey.setValue(unstringifyBigInts2(await (await fetch('/circuits/withdraw_verification_key.json')).json()));
    this.verificationKey.setLoading(false);
    return this.verificationKey.value;
  }

  async readCircuit() {
    if (this.circuit.value) {
      return this.circuit.value;
    }
    this.circuit.setLoading(true);
    this.circuit.setValue(await (await fetch(Config.assetURL + '/circuits/withdraw.json')).json());
    this.circuit.setLoading(false);
    return this.circuit.value;
  }

  async snarkVerify(proof) {
    proof = unstringifyBigInts2(proof);
    const verification_key = await this.readVerificationKey();
    return snarkjs['groth'].isValid(verification_key, proof, proof.publicSignals);
  }

  verifyNote(note: string): NoteData {
    const parsedNote = GodUtils.parseNoteV2(note);
    if (!parsedNote) {
      return GodUtils.parseNoteV1(note);
    }
    return parsedNote;
  }
  async generateMerkleProof({ deposit, pool }: { deposit: Partial<DepositNote>; pool: PoolState }) {
    const history = await this.currentNetwork.loadPoolLog(pool);
    const leaves = history.deposits.map((i) => i.commitment);
    console.log('findOneDepositEvent() leaves =', leaves);

    const depositEvent = history.deposits.find((e) => e.commitment === GodUtils.toHex(deposit.commitment, 32));
    console.log('getIsKnownRoot depositEvent =', depositEvent);

    const tree = new merkleTree(Config.merkleTreeHeight, leaves);
    console.log('getIsKnownRoot tree =', tree);

    const leafIndex = depositEvent ? depositEvent.leafIndex : -1;
    console.log('getIsKnownRoot leafIndex =', leafIndex);

    const root = await tree.root();
    console.log('getIsKnownRoot root =', root);

    const [isValidRoot, isSpent] = await this.currentNetwork.multicall([
      { address: pool.address, abi: pool.abi, method: 'isKnownRoot', params: [GodUtils.toHex(root, 32)] },
      { address: pool.address, abi: pool.abi, method: 'isSpent', params: [GodUtils.toHex(deposit.nullifierHash, 32)] }
    ]);
    console.log('getIsKnownRoot =', isValidRoot, typeof isValidRoot, 'get isSpent=', isSpent, 'leafIndex=', leafIndex);
    if (isSpent || !isValidRoot || leafIndex < 0) {
      return {
        spent: isSpent,
        invalidRoot: !isValidRoot,
        invalidLeafIndex: leafIndex < 0
      };
    }
    const treePath = await tree.path(leafIndex);
    return { treePath };
  }
  async preWithdraw({
    deposit,
    recipient,
    pool,
    relayerAddress
  }: {
    deposit: Partial<DepositNote>;
    recipient: any;
    pool: PoolState;
    relayerAddress: string;
  }) {
    const retval = await this.generateProof({
      deposit,
      recipient,
      relayerAddress,
      pool,
      refund: 0
    });
    return retval;
  }

  async generateProof({
    deposit,
    recipient,
    relayerAddress,
    refund,
    pool
  }: {
    deposit: Partial<DepositNote>;
    recipient: any;
    relayerAddress: string;
    refund?: number;
    pool: PoolState;
  }) {
    const { treePath, spent, invalidRoot, invalidLeafIndex } = await this.generateMerkleProof({ deposit, pool });
    if (!treePath) {
      return {
        spent,
        invalidRoot,
        invalidLeafIndex
      };
    }
    const { root, path_elements: pathElements, path_index: pathIndices } = treePath;
    const { nullifierHash, nullifier, secret } = deposit;
    const { relayerFee } = pool;
    const input = {
      // Public snark inputs
      root,
      nullifierHash,
      recipient: this.isETH ? recipient : bigInt(bytesToHex(Address.fromString(recipient).bytes())),
      relayer: this.isETH ? relayerAddress : bigInt(relayerAddress),
      fee: bigInt(relayerFee.value.toString()),
      refund: bigInt(refund),

      // Private snark inputs
      nullifier,
      secret,
      pathElements,
      pathIndices
    };
    const groth16 = await buildGroth16();
    const circuit = await this.readCircuit();
    const proving_key = await this.readProvingKey();
    const proofData = await websnarkUtils.genWitnessAndProve(groth16, input, circuit, proving_key);

    if (!this.snarkVerify(proofData)) {
      return {
        invalidProof: true
      };
    }
    const { proof } = websnarkUtils.toSolidityInput(proofData);

    // eth address -> io address
    const recipientBytes = await hexToBytes(GodUtils.toHex(input.recipient, 20));
    const ioRecipient = await Address.fromBytes(recipientBytes);
    const relayerBytes = await hexToBytes(GodUtils.toHex(input.relayer, 20));
    const ioRelayer = await Address.fromBytes(relayerBytes);
    const args = [
      proof,
      GodUtils.toHex(input.root, 32),
      GodUtils.toHex(input.nullifierHash, 32),
      this.isETH ? recipient : ioRecipient.string(),
      this.isETH ? relayerAddress : ioRelayer.string(),
      GodUtils.toHex(input.fee, 32),
      GodUtils.toHex(input.refund, 32)
    ];

    return { args };
  }

  async isSpent({ pool, nullifier }: { pool: PoolState; nullifier: string }) {
    return this.currentNetwork
      .execContract({
        address: pool.address,
        abi: pool.abi,
        method: 'isSpent',
        params: [nullifier],
        read: true
      })
      .then((res) => {
        console.log({ res });
        return Boolean(res);
      });
  }

  async getRelayer() {
    const res = await axios.get(this.currentChain.relayer + '/status/');
    return res.data ? res.data.relayerAddress : null;
  }
}
